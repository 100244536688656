<template>
  <div class="container tech" data-view>
    <el-row type="flex" justify="space-between" align="middle" class="tech-row">
      <el-col :xs="24" :sm="12" class="tech-text">
        <div class="tech-img d-hide">
          <img
            src="@/assets/img/success.svg"
            alt=""
            data-copyright-source="https://storyset.com/illustration/mail-sent/pana"
            data-copyright-name="Storyset"
            data-copyright-owner="Storyset"
            data-copyright-profile="https://storyset.com/"
          />
        </div>
        <h2>Ваша заявка получена и&nbsp;находится в&nbsp;обработке</h2>
        <p class="p-3 bold">
          В&nbsp;ближайшее время с&nbsp;вами свяжутся менеджеры и&nbsp;расскажут
          о&nbsp;дальнейших действиях
        </p>
        <p class="p-4">
          Если остались ещё какие-то вопросы, предлагаем перейти
          на&nbsp;<router-link :to="{ name: 'Home' }"
            >главную страницу</router-link
          >
          сайта
        </p>
      </el-col>
      <el-col :xs="24" :sm="10" class="tech-img m-hide">
        <img
          src="@/assets/img/success.svg"
          alt=""
          data-copyright-source="https://storyset.com/illustration/mail-sent/pana"
          data-copyright-name="Storyset"
          data-copyright-owner="Storyset"
          data-copyright-profile="https://storyset.com/"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
const meta = {
  title: "Заявка успешно отправлена",
};

export default {
  metaInfo: {
    title: meta.title,
    meta: [
      {
        name: "og:title",
        content: meta.title,
      },
      {
        name: "robots",
        content: "none",
      },
    ],
  },
};
</script>
